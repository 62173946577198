// src/pages/DiscountPage.js
import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    InputAdornment,
    IconButton,
    Menu,
    Checkbox,
    ListItemText,
    Divider,
} from '@mui/material';
import {
    FilterAltOutlined,
    FilterAlt,
    Search,
    ArrowDropDown,
    ArrowDropUp,
} from '@mui/icons-material';
import {
    collection,
    getDocs,
    writeBatch,
    doc,
} from 'firebase/firestore';
import { db } from '../../firebase';
import Papa from 'papaparse';

const DiscountPage = () => {
    // Data state
    const [vouchers, setVouchers] = useState([]);
    const [registeredEmails, setRegisteredEmails] = useState(new Set());
    const [loading, setLoading] = useState(true);

    // CSV import state
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [csvData, setCsvData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [codeHeader, setCodeHeader] = useState('');
    const [firstNameHeader, setFirstNameHeader] = useState('');
    const [lastNameHeader, setLastNameHeader] = useState('');
    const [emailHeader, setEmailHeader] = useState('');
    const [defaultBalance, setDefaultBalance] = useState(0);
    const [importing, setImporting] = useState(false);

    // Filtering, Sorting & Search state for the voucher table
    const [globalSearch, setGlobalSearch] = useState('');
    const [columnFilters, setColumnFilters] = useState({});
    const [filterAnchors, setFilterAnchors] = useState({});
    const [filterSearchTexts, setFilterSearchTexts] = useState({});
    const [sortOrder, setSortOrder] = useState({}); // key: column id, value: 'asc' | 'desc'

    // Load vouchers and registered users
    useEffect(() => {
        (async () => {
            setLoading(true);
            // Fetch vouchers from the 'discounts' collection.
            const snapV = await getDocs(collection(db, 'discounts'));
            const voucherList = snapV.docs.map(d => ({ id: d.id, ...d.data() }));
            setVouchers(voucherList);

            // Fetch users from the 'users' collection and build a Set of emails (lowercased).
            const snapU = await getDocs(collection(db, 'users'));
            const emails = new Set(
                snapU.docs.map(d => d.data().email?.toLowerCase()).filter(Boolean)
            );
            setRegisteredEmails(emails);

            setLoading(false);
        })();
    }, []);

    // CSV Import handlers
    const openImport = () => {
        setStep(1);
        setCsvData([]);
        setHeaders([]);
        setCodeHeader('');
        setFirstNameHeader('');
        setLastNameHeader('');
        setEmailHeader('');
        setDefaultBalance(0);
        setOpen(true);
    };
    const closeImport = () => setOpen(false);
    const onFile = e => {
        const file = e.target.files[0];
        if (!file) return;
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: res => {
                setCsvData(res.data);
                setHeaders(res.meta.fields);
            },
        });
    };
    const toPreview = () => {
        if (!codeHeader) return;
        setStep(2);
    };
    const confirmImport = async () => {
        setImporting(true);
        const batch = writeBatch(db);
        csvData.forEach(row => {
            const codeValue = row[codeHeader]?.trim();
            if (!codeValue) return;
            const data = {
                balance: Number(defaultBalance),
                type: 'discountVoucher',
                uses: 0,
                usedBy: [],
                usedOn: [],
            };
            if (firstNameHeader) data.expectedFirstName = row[firstNameHeader]?.trim() || '';
            if (lastNameHeader) data.expectedLastName = row[lastNameHeader]?.trim() || '';
            if (emailHeader) data.expectedEmail = row[emailHeader]?.trim() || '';
            const ref = doc(db, 'discounts', codeValue);
            batch.set(ref, data);
        });
        await batch.commit();
        // Refresh vouchers list
        const snap = await getDocs(collection(db, 'discounts'));
        setVouchers(snap.docs.map(d => ({ id: d.id, ...d.data() })));
        setImporting(false);
        setOpen(false);
    };

    // Define table columns and an accessor for each column.
    // Note: Numeric values are converted to strings for filtering.
    const columns = [
        { id: 'code', label: 'Code', getValue: (v) => v.id },
        { id: 'balance', label: 'Balance', getValue: (v) => v.balance.toString() },
        { id: 'uses', label: 'Uses', getValue: (v) => v.uses.toString() },
        {
            id: 'numUsers',
            label: '# Users',
            getValue: (v) => (v.usedBy ? v.usedBy.length : 0).toString(),
        },
        {
            id: 'numUses',
            label: '# Uses',
            getValue: (v) => (v.usedOn ? v.usedOn.length : 0).toString(),
        },
        {
            id: 'expectedFirstName',
            label: 'First Name',
            getValue: (v) => v.expectedFirstName || '—',
        },
        {
            id: 'expectedLastName',
            label: 'Last Name',
            getValue: (v) => v.expectedLastName || '—',
        },
        {
            id: 'expectedEmail',
            label: 'Email',
            getValue: (v) => v.expectedEmail || '—',
        },
        {
            id: 'registered',
            label: 'Registered',
            getValue: (v) => {
                const email = v.expectedEmail?.toLowerCase() || '';
                return email && registeredEmails.has(email) ? 'Yes' : 'No';
            },
        },
    ];

    // Filter & sort logic: create a filtered list of vouchers
    const filteredVouchers = useMemo(() => {
        return vouchers.filter(v => {
            // Global search filtering (checks every column)
            if (globalSearch) {
                const lowerSearch = globalSearch.toLowerCase();
                const matchesGlobal = columns.some(col =>
                    col.getValue(v).toString().toLowerCase().includes(lowerSearch)
                );
                if (!matchesGlobal) return false;
            }
            // Column-specific filters
            for (let col of columns) {
                const activeFilters = columnFilters[col.id] || [];
                if (activeFilters.length > 0) {
                    const value = col.getValue(v).toString();
                    if (!activeFilters.includes(value)) return false;
                }
            }
            return true;
        });
    }, [vouchers, globalSearch, columnFilters, registeredEmails, columns]);

    // Sorting: Apply sort orders (you can sort by multiple columns in order of definition)
    const sortedVouchers = useMemo(() => {
        let data = [...filteredVouchers];
        Object.keys(sortOrder).forEach(colId => {
            const order = sortOrder[colId];
            const col = columns.find(c => c.id === colId);
            if (col) {
                data.sort((a, b) => {
                    const aVal = col.getValue(a);
                    const bVal = col.getValue(b);
                    // If values are numeric, sort numerically; otherwise, sort as strings
                    if (!isNaN(aVal) && !isNaN(bVal)) {
                        return order === 'asc' ? aVal - bVal : bVal - aVal;
                    } else {
                        if (aVal < bVal) return order === 'asc' ? -1 : 1;
                        if (aVal > bVal) return order === 'asc' ? 1 : -1;
                        return 0;
                    }
                });
            }
        });
        return data;
    }, [filteredVouchers, sortOrder, columns]);

    // Handlers for column filters and sort
    const handleFilterClick = (event, colId) => {
        setFilterAnchors(prev => ({ ...prev, [colId]: event.currentTarget }));
    };
    const handleFilterClose = (colId) => {
        setFilterAnchors(prev => ({ ...prev, [colId]: null }));
    };
    const handleFilterSearchChange = (event, colId) => {
        setFilterSearchTexts(prev => ({ ...prev, [colId]: event.target.value }));
    };
    const handleFilterChange = (colId, value) => {
        setColumnFilters(prev => {
            const current = prev[colId] || [];
            let newFilters;
            if (current.includes(value)) {
                newFilters = current.filter(v => v !== value);
            } else {
                newFilters = [...current, value];
            }
            return { ...prev, [colId]: newFilters };
        });
    };
    const handleSelectAll = (colId) => {
        const col = columns.find(c => c.id === colId);
        if (!col) return;
        const distinctValues = Array.from(
            new Set(vouchers.map(v => col.getValue(v).toString()))
        ).sort();
        setColumnFilters(prev => ({ ...prev, [colId]: distinctValues }));
    };
    const handleDeselectAll = (colId) => {
        setColumnFilters(prev => ({ ...prev, [colId]: [] }));
    };
    const handleSort = (colId) => {
        setSortOrder(prev => {
            const current = prev[colId];
            const next = current === 'asc' ? 'desc' : 'asc';
            return { ...prev, [colId]: next };
        });
    };

    // Progress bar calculations based on usedOn entries
    const totalVoucherAmount = vouchers.reduce(
        (acc, v) => acc + Number(v.balance),
        0
    );
    const totalUsedVoucherAmount = vouchers.reduce((accV, v) => {
        const sumThis = (v.usedOn || []).reduce(
            (accU, u) => accU + Number(u.amountUsed),
            0
        );
        return accV + sumThis;
    }, 0);
    const progressPercentage = totalVoucherAmount
        ? (totalUsedVoucherAmount / totalVoucherAmount) * 100
        : 0;

    // Summary counts above the table
    const totalVouchersCount = vouchers.length;
    const registeredCount = vouchers.filter(v => {
        const email = v.expectedEmail?.toLowerCase() || '';
        return email && registeredEmails.has(email);
    }).length;

    return (
        <Box p={2}>
            {/* Progress Bar */}
            <Box display="flex" alignItems="center" mb={2}>
                <Box
                    sx={{
                        flexGrow: 1,
                        position: 'relative',
                        height: '24px',
                        backgroundColor: '#e0e0e0',
                        borderRadius: '4px',
                        mr: 2,
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            width: `${progressPercentage}%`,
                            backgroundColor: 'green',
                            borderRadius: '4px',
                        }}
                    />
                </Box>
                <Typography variant="body1">
                    ${totalVoucherAmount.toFixed(2)} total &nbsp;|&nbsp; ${totalUsedVoucherAmount.toFixed(2)} used
                </Typography>
            </Box>

            <Typography variant="h4" gutterBottom>
                Discount Vouchers
            </Typography>

            <Button variant="contained" onClick={openImport} sx={{ mb: 2 }}>
                Import CSV
            </Button>

            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    {/* Summary above table */}
                    <Box mb={1} display="flex" gap={4}>
                        <Typography>
                            Total vouchers: <strong>{totalVouchersCount}</strong>
                        </Typography>
                        <Typography>
                            Registered qualifiers: <strong>{registeredCount}</strong>
                        </Typography>
                    </Box>

                    {/* Global Search Bar */}
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            placeholder="Search vouchers..."
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={globalSearch}
                            onChange={(e) => setGlobalSearch(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    {/* Voucher Table */}
                    <Paper>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {columns.map(col => (
                                        <TableCell
                                            key={col.id}
                                            sx={{
                                                fontSize: { xs: '0.7rem', md: '0.9rem' },
                                                pr: 0,
                                                pl: 2,
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography fontWeight="bold">
                                                    {col.label}
                                                </Typography>
                                                {/* Filter Icon */}
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => handleFilterClick(e, col.id)}
                                                    sx={{
                                                        fontWeight: (columnFilters[col.id]?.length ? 'bold' : 'normal'),
                                                    }}
                                                >
                                                    {columnFilters[col.id]?.length ? (
                                                        <FilterAlt fontSize="small" />
                                                    ) : (
                                                        <FilterAltOutlined fontSize="small" />
                                                    )}
                                                </IconButton>
                                                {/* Sort Toggle */}
                                                <IconButton size="small" onClick={() => handleSort(col.id)}>
                                                    {sortOrder[col.id] === 'asc' ? (
                                                        <ArrowDropUp fontSize="small" />
                                                    ) : (
                                                        <ArrowDropDown fontSize="small" />
                                                    )}
                                                </IconButton>
                                                {/* Filter Menu */}
                                                <Menu
                                                    anchorEl={filterAnchors[col.id]}
                                                    open={Boolean(filterAnchors[col.id])}
                                                    onClose={() => handleFilterClose(col.id)}
                                                >
                                                    <Box sx={{ p: 1, minWidth: 200 }}>
                                                        <TextField
                                                            variant="outlined"
                                                            placeholder="Search..."
                                                            fullWidth
                                                            size="small"
                                                            value={filterSearchTexts[col.id] || ''}
                                                            onChange={(e) => handleFilterSearchChange(e, col.id)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <Search fontSize="small" />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                            <Button onClick={() => handleSelectAll(col.id)} size="small">
                                                                Select All
                                                            </Button>
                                                            <Button onClick={() => handleDeselectAll(col.id)} size="small">
                                                                Deselect All
                                                            </Button>
                                                        </Box>
                                                        <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                                            {Array.from(
                                                                new Set(
                                                                    vouchers.map(v => col.getValue(v).toString())
                                                                )
                                                            )
                                                                .filter(val =>
                                                                    !filterSearchTexts[col.id] ||
                                                                    val.toLowerCase().includes(filterSearchTexts[col.id].toLowerCase())
                                                                )
                                                                .sort()
                                                                .map(val => (
                                                                    <MenuItem
                                                                        key={val}
                                                                        onClick={() => handleFilterChange(col.id, val)}
                                                                    >
                                                                        <Checkbox
                                                                            checked={
                                                                                (columnFilters[col.id] || []).includes(val)
                                                                            }
                                                                            size="small"
                                                                        />
                                                                        <ListItemText primary={val} />
                                                                    </MenuItem>
                                                                ))}
                                                        </Box>
                                                    </Box>
                                                </Menu>
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedVouchers.map((voucher, index) => (
                                    <TableRow key={voucher.id} sx={{ backgroundColor: index % 2 === 0 ? 'inherit' : 'inherit' }}>
                                        {columns.map(col => (
                                            <TableCell key={col.id} sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                                {col.getValue(voucher)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </>
            )}

            {/* Import Dialog */}
            <Dialog open={open} onClose={closeImport} fullWidth maxWidth="md">
                <DialogTitle>
                    {step === 1 ? 'Import Settings' : 'Preview & Confirm'}
                </DialogTitle>
                <DialogContent dividers>
                    {step === 1 && (
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Button variant="outlined" component="label">
                                Select CSV File
                                <input type="file" accept=".csv" hidden onChange={onFile} />
                            </Button>
                            {headers.length > 0 && (
                                <>
                                    <FormControl fullWidth>
                                        <InputLabel>Code Column</InputLabel>
                                        <Select
                                            value={codeHeader}
                                            label="Code Column"
                                            onChange={e => setCodeHeader(e.target.value)}
                                        >
                                            {headers.map(h => (
                                                <MenuItem key={h} value={h}>{h}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>First Name Column</InputLabel>
                                        <Select
                                            value={firstNameHeader}
                                            label="First Name Column"
                                            onChange={e => setFirstNameHeader(e.target.value)}
                                        >
                                            <MenuItem value="">— none —</MenuItem>
                                            {headers.map(h => (
                                                <MenuItem key={h} value={h}>{h}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Last Name Column</InputLabel>
                                        <Select
                                            value={lastNameHeader}
                                            label="Last Name Column"
                                            onChange={e => setLastNameHeader(e.target.value)}
                                        >
                                            <MenuItem value="">— none —</MenuItem>
                                            {headers.map(h => (
                                                <MenuItem key={h} value={h}>{h}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Email Column</InputLabel>
                                        <Select
                                            value={emailHeader}
                                            label="Email Column"
                                            onChange={e => setEmailHeader(e.target.value)}
                                        >
                                            <MenuItem value="">— none —</MenuItem>
                                            {headers.map(h => (
                                                <MenuItem key={h} value={h}>{h}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Default Balance"
                                        type="number"
                                        fullWidth
                                        value={defaultBalance}
                                        onChange={e => setDefaultBalance(e.target.value)}
                                    />
                                </>
                            )}
                        </Box>
                    )}
                    {step === 2 && (
                        <>
                            <Typography gutterBottom>
                                Preview the {csvData.length} codes to be imported:
                            </Typography>
                            <Paper sx={{ maxHeight: 300, overflow: 'auto' }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code</TableCell>
                                            {firstNameHeader && <TableCell>First Name</TableCell>}
                                            {lastNameHeader && <TableCell>Last Name</TableCell>}
                                            {emailHeader && <TableCell>Email</TableCell>}
                                            <TableCell>Balance</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {csvData.map((row, i) => {
                                            const code = row[codeHeader]?.trim() || '—';
                                            const fn = firstNameHeader ? row[firstNameHeader]?.trim() : '';
                                            const ln = lastNameHeader ? row[lastNameHeader]?.trim() : '';
                                            const em = emailHeader ? row[emailHeader]?.trim() : '';
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell>{code}</TableCell>
                                                    {firstNameHeader && <TableCell>{fn}</TableCell>}
                                                    {lastNameHeader && <TableCell>{ln}</TableCell>}
                                                    {emailHeader && <TableCell>{em}</TableCell>}
                                                    <TableCell>${defaultBalance}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {step === 2 && (
                        <Button onClick={() => setStep(1)} disabled={importing}>
                            Back
                        </Button>
                    )}
                    <Button onClick={closeImport} disabled={importing}>
                        Cancel
                    </Button>
                    {step === 1 ? (
                        <Button
                            onClick={toPreview}
                            disabled={!codeHeader || !csvData.length}
                            variant="contained"
                        >
                            Next: Preview
                        </Button>
                    ) : (
                        <Button onClick={confirmImport} disabled={importing} variant="contained">
                            {importing ? 'Importing…' : 'Confirm Import'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DiscountPage;
