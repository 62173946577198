import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  People as PeopleIcon,
  Assessment as AssessmentIcon,
  AttachMoney as AttachMoneyIcon,
} from '@mui/icons-material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from 'recharts';
import {
  collection,
  getDocs,
  collectionGroup,
  query,
  orderBy,
} from 'firebase/firestore';
import { db } from '../../firebase';

const AdminDashboard = () => {
  // Helpers
  const getFormattedDate = date => date.toISOString().split('T')[0];
  const getReadableDate = date => new Date(date).toLocaleDateString('en-US');

  // Date defaults
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 3);
  const oneMonthAgo = new Date(tomorrow);
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  // State
  const [userDocs, setUserDocs] = useState([]);
  const [cumulativeUserData, setCumulativeUserData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [products, setProducts] = useState({});
  const [startDate, setStartDate] = useState(getFormattedDate(oneMonthAgo));
  const [endDate, setEndDate] = useState(getFormattedDate(tomorrow));

  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState('university_1');
  const [containerLocations, setContainerLocations] = useState([]);
  const [containerAppointments, setContainerAppointments] = useState({});
  const [containerLocationChartData, setContainerLocationChartData] = useState([]);
  const [moveInChartData, setMoveInChartData] = useState([]);
  const [moveOutChartData, setMoveOutChartData] = useState([]);

  const [invoices, setInvoices] = useState([]);
  const [actualRevenue, setActualRevenue] = useState(0);
  const [averageRevenue, setAverageRevenue] = useState(0);
  const [paidCustomerCount, setPaidCustomerCount] = useState(0);

  const [specialAppointments, setSpecialAppointments] = useState([]);
  const [specialAppAggData, setSpecialAppAggData] = useState([]);
  const [specialAppRevenueData, setSpecialAppRevenueData] = useState({});

  // Fetchers
  const fetchUserDocs = async () => {
    try {
      const snap = await getDocs(collection(db, 'users'));
      const users = [];
      snap.forEach(doc => {
        const d = doc.data();
        users.push({
          id: doc.id,
          ...d,
          registrationDate: d.registrationDate.toDate(),
        });
      });
      setUserDocs(users);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchProductDocs = async () => {
    try {
      const snap = await getDocs(collection(db, 'products'));
      const m = {};
      snap.forEach(doc => {
        const d = doc.data();
        m[doc.id] = { internalName: d.internalName, price: d.price || 0 };
      });
      setProducts(m);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUniversities = async () => {
    try {
      const snap = await getDocs(collection(db, 'universities'));
      const list = [];
      snap.forEach(doc => list.push({ id: doc.id, name: doc.data().name }));
      setUniversities(list);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchContainerLocations = async u => {
    try {
      const snap = await getDocs(collection(db, `universities/${u}/containerLocations`));
      const locs = [];
      snap.forEach(doc => {
        const d = doc.data();
        locs.push({
          id: doc.id,
          name: d.name,
          shortName: d.shortName,
          isOffCampus: d.isOffCampus || false,
        });
      });
      setContainerLocations(locs);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAppointmentsForContainer = async locId => {
    try {
      const snap = await getDocs(
        collection(db, `universities/${selectedUniversity}/containerLocations/${locId}/appointments`)
      );
      const appts = {};
      snap.forEach(doc => {
        const d = doc.data();
        appts[doc.id] = { id: doc.id, start: d.start.toDate(), type: d.type };
      });
      setContainerAppointments(prev => ({ ...prev, [locId]: appts }));
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAllInvoices = async () => {
    try {
      const q = query(collectionGroup(db, 'invoices'), orderBy('createdAt', 'desc'));
      const snap = await getDocs(q);
      const invs = snap.docs.map(docSnap => {
        const d = docSnap.data();
        const [, userID] = docSnap.ref.path.split('/');
        return { userID, isPaid: !!d.isPaid, total: d.paymentDetails?.invoiceTotals?.total || 0 };
      });
      setInvoices(invs);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSpecialAppointments = async u => {
    try {
      const snap = await getDocs(collection(db, `universities/${u}/specialAppointments`));
      const specs = [];
      snap.forEach(doc => {
        const d = doc.data();
        specs.push({ id: doc.id, ...d, start: d.start?.toDate() });
      });
      setSpecialAppointments(specs);
    } catch (e) {
      console.error(e);
    }
  };

  // Effects
  useEffect(() => {
    fetchUserDocs();
    fetchProductDocs();
    fetchUniversities();
    fetchAllInvoices();
  }, []);

  useEffect(() => {
    if (!selectedUniversity) return;
    fetchContainerLocations(selectedUniversity);
    setContainerAppointments({});
    fetchSpecialAppointments(selectedUniversity);
  }, [selectedUniversity]);

  useEffect(() => {
    if (!containerLocations.length) return;
    containerLocations.forEach(loc => fetchAppointmentsForContainer(loc.id));
  }, [containerLocations]);

  // Cumulative registrations
  useEffect(() => {
    if (!userDocs.length) return;
    const getDateRange = (s, e) => {
      const start = new Date(s),
        end = new Date(e),
        arr = [];
      let cur = new Date(start);
      while (cur <= end) {
        arr.push(cur.toISOString().split('T')[0]);
        cur.setDate(cur.getDate() + 1);
      }
      return arr;
    };
    const dates = getDateRange(startDate, endDate);
    const sorted = [...userDocs].sort((a, b) => a.registrationDate - b.registrationDate);
    let cum = 0,
      ver = 0,
      prevCum = 0,
      prevVer = 0;
    const data = dates.map(ds => {
      const d = new Date(ds);
      while (sorted.length && sorted[0].registrationDate <= d) {
        cum++;
        if (sorted[0].emailVerified) ver++;
        sorted.shift();
      }
      const daily = cum - prevCum;
      const dailyVer = ver - prevVer;
      prevCum = cum;
      prevVer = ver;
      return { date: ds, totalUsers: cum, dailyJoined: daily, emailVerified: ver, dailyVerified: dailyVer };
    });
    setCumulativeUserData(data);
  }, [userDocs, startDate, endDate]);

  // Plan breakdown
  useEffect(() => {
    if (!userDocs.length) return;
    const counts = {};
    userDocs.forEach(u => {
      const p = u.storagePlan || 'Unselected';
      counts[p] = (counts[p] || 0) + 1;
    });
    const arr = Object.entries(counts).map(([code, val]) => ({
      planCode: code,
      name: code === 'Unselected' ? 'Unselected' : products[code]?.internalName || code,
      value: val,
    }));
    const order = ['0001', '0002', '0003', '0004'];
    arr.sort((a, b) => {
      if (a.planCode === 'Unselected') return 1;
      if (b.planCode === 'Unselected') return -1;
      return order.indexOf(a.planCode) - order.indexOf(b.planCode);
    });
    setPlanData(arr);
  }, [userDocs, products]);

  // Container-location chart
  useEffect(() => {
    if (!containerLocations.length) {
      setContainerLocationChartData([]);
      return;
    }
    const data = containerLocations.map(loc => {
      const moveIn = userDocs.filter(u => u.moveInContainerLocationID === loc.id).length;
      const moveOut = userDocs.filter(u => u.moveOutContainerLocationID === loc.id).length;
      const appointmentCounts = {};
      const appts = containerAppointments[loc.id] || {};
      Object.values(appts).forEach(a => {
        const day = a.start.toISOString().split('T')[0];
        appointmentCounts[day] = appointmentCounts[day] || { moveIn: 0, moveOut: 0 };
        appointmentCounts[day].moveIn += userDocs.filter(u => u.moveInAppointment === a.id).length;
        appointmentCounts[day].moveOut += userDocs.filter(u => u.moveOutAppointment === a.id).length;
      });
      return { locationShortName: loc.shortName, fullName: loc.name, moveIn, moveOut, appointmentCounts };
    });
    setContainerLocationChartData(data);
  }, [containerLocations, containerAppointments, userDocs]);

  // Appointment bar charts
  const aggregateAppointmentChartData = type =>
    containerLocations.map(loc => {
      const obj = { containerShortName: loc.shortName };
      const appts = containerAppointments[loc.id] || {};
      Object.values(appts).forEach(a => {
        if (a.type === type) {
          const day = a.start.toISOString().split('T')[0];
          const cnt = userDocs.filter(u =>
            type === 'Move In' ? u.moveInAppointment === a.id : u.moveOutAppointment === a.id
          ).length;
          obj[day] = (obj[day] || 0) + cnt;
        }
      });
      return obj;
    });
  useEffect(() => {
    setMoveInChartData(aggregateAppointmentChartData('Move In'));
    setMoveOutChartData(aggregateAppointmentChartData('Move Out'));
  }, [containerLocations, containerAppointments, userDocs]);

  // Invoice revenue & counts
  useEffect(() => {
    const paid = invoices.filter(i => i.isPaid);
    const actual = paid.reduce((s, i) => s + i.total, 0);
    const uniqueCustomers = new Set(paid.map(i => i.userID));
    setActualRevenue(actual);
    setAverageRevenue(uniqueCustomers.size ? actual / uniqueCustomers.size : 0);
    setPaidCustomerCount(uniqueCustomers.size);
  }, [invoices]);

  // Anticipated plan revenue
  const totalPlanRevenue = planData.reduce((s, p) => {
    if (p.planCode !== 'Unselected') {
      return s + (products[p.planCode]?.price || 0) * p.value;
    }
    return s;
  }, 0);

  // Special-appointments + off-campus aggregation
  const [totalSpecialRevenue, setTotalSpecialRevenue] = useState(0);
  useEffect(() => {
    const groups = {
      'Early Move In': { count: 0, revenue: 0 },
      'Early Move Out': { count: 0, revenue: 0 },
      'Late Move In': { count: 0, revenue: 0 },
      'Late Move Out': { count: 0, revenue: 0 },
      'Off Campus': { count: 0, revenue: 0 },
    };

    // special appointments
    specialAppointments.forEach(app => {
      const key = `${app.category} ${app.type}`; // "Early Move In", etc.
      if (groups[key]) {
        groups[key].count += 1;
        groups[key].revenue += products[app.productCode]?.price || 0;
      }
    });

    // off-campus users
    const offCampusLocIds = new Set(
      containerLocations.filter(l => l.isOffCampus).map(l => l.id)
    );
    const countedUsers = new Set();
    userDocs.forEach(u => {
      if (
        !countedUsers.has(u.id) &&
        (offCampusLocIds.has(u.moveInContainerLocationID) ||
          offCampusLocIds.has(u.moveOutContainerLocationID))
      ) {
        groups['Off Campus'].count += 1;
        groups['Off Campus'].revenue += products['OFFC']?.price || 0;
        countedUsers.add(u.id);
      }
    });

    const pieData = Object.entries(groups).map(([group, data]) => ({
      group,
      value: data.count,
    }));
    setSpecialAppAggData(pieData);
    setSpecialAppRevenueData(groups);

    // sum total special revenue
    const sumSpecial = Object.values(groups).reduce((s, g) => s + g.revenue, 0);
    setTotalSpecialRevenue(sumSpecial);
  }, [specialAppointments, userDocs, containerLocations, products]);

  // Chart tooltips & helpers
  const CustomLineTooltip = ({ active, payload, label }) => {
    if (!active || !payload.length) return null;
    const day = new Date(label).toLocaleDateString('en-US', { weekday: 'long' });
    const rd = getReadableDate(label);
    const total = payload.find(p => p.dataKey === 'totalUsers')?.value;
    const joined = payload.find(p => p.dataKey === 'dailyJoined')?.value;
    const verified = payload.find(p => p.dataKey === 'emailVerified')?.value;
    return (
      <div style={{ background: '#fff', border: '1px solid #ccc', padding: 10 }}>
        <p><strong>{day}</strong></p>
        <p>Date: {rd}</p>
        <p>Total Registered Users: {total}</p>
        <p>Users Joined: {joined}</p>
        <p>Email Verified Users: {verified}</p>
      </div>
    );
  };
  const renderCustomPieTooltip = ({ active, payload }) => {
    if (!active || !payload.length) return null;
    const { group, value } = payload[0].payload;
    const total = specialAppAggData.reduce((s, e) => s + e.value, 0);
    const pct = total ? ((value / total) * 100).toFixed(2) : '0.00';
    return (
      <div style={{ background: '#fff', border: '1px solid #ccc', padding: 10 }}>
        <p><strong>{group}</strong></p>
        <p>Count: {value}</p>
        <p>Percentage: {pct}%</p>
      </div>
    );
  };
  const renderContainerTooltip = ({ active, payload }) => {
    if (!active || !payload.length) return null;
    const d = payload[0].payload;
    return (
      <div style={{ background: '#fff', border: '1px solid #ccc', padding: 10 }}>
        <p><strong>{d.fullName}</strong></p>
        <p>Move In: {d.moveIn}</p>
        <p>Move Out: {d.moveOut}</p>
        {d.appointmentCounts && Object.keys(d.appointmentCounts).length > 0 && (
          <>
            <p style={{ marginTop: 10, fontWeight: 'bold' }}>Appointments</p>
            {Object.entries(d.appointmentCounts).map(([day, c]) => (
              <p key={day}>{day}: Move In {c.moveIn}, Move Out {c.moveOut}</p>
            ))}
          </>
        )}
      </div>
    );
  };
  const getUnionKeys = arr => {
    const s = new Set();
    arr.forEach(o => Object.keys(o).forEach(k => k !== 'containerShortName' && s.add(k)));
    return Array.from(s).sort();
  };
  const moveInKeys = getUnionKeys(moveInChartData);
  const moveOutKeys = getUnionKeys(moveOutChartData);
  const getOrdinal = n => {
    const sfx = ['th', 'st', 'nd', 'rd'],
      v = n % 100;
    return n + (sfx[(v - 20) % 10] || sfx[v] || sfx[0]);
  };
  const formatAppointmentLabel = ds => {
    const d = new Date(ds);
    const m = d.toLocaleString('default', { month: 'long' });
    return `${m} ${getOrdinal(d.getDate())}`;
  };

  const PIE_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28DD0', '#D0A28D'];
  const BAR_COLORS = ['#8884d8', '#82ca9d'];

  // Combined anticipated revenue
  const combinedAnticipatedRevenue = totalPlanRevenue + totalSpecialRevenue;

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>

      {/* Top stats */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Paper sx={{ p: 2, backgroundColor: '#e3f2fd', border: '1px solid #0b2b50', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <PeopleIcon sx={{ fontSize: 40, color: '#0b2b50' }} />
                <Typography variant="h6">Registered Users</Typography>
                <Typography>{userDocs.length}</Typography>
              </Paper>
            </Grid>
            <Grid item>
              <Paper sx={{ p: 2, backgroundColor: '#e3f2fd', border: '1px solid #0b2b50', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <AssessmentIcon sx={{ fontSize: 40, color: '#0b2b50' }} />
                <Typography variant="h6">Paid Customers</Typography>
                <Typography>{paidCustomerCount}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2, backgroundColor: '#e3f2fd', border: '1px solid #0b2b50' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AttachMoneyIcon sx={{ fontSize: 40, color: '#0b2b50', mr: 2 }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box>
                  <Typography variant="subtitle2">Anticipated</Typography>
                  <Typography>${combinedAnticipatedRevenue.toLocaleString()}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">Actual</Typography>
                  <Typography>${actualRevenue.toLocaleString()}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">Average / Customer</Typography>
                  <Typography>${averageRevenue.toFixed(2)}</Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Filters */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="End Date"
            type="date"
            fullWidth
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>University</InputLabel>
            <Select
              value={selectedUniversity}
              label="University"
              onChange={e => setSelectedUniversity(e.target.value)}
            >
              {universities.map(u => (
                <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Cumulative Registered Users</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={cumulativeUserData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={{ fontSize: 10 }} />
                <YAxis />
                <RechartsTooltip content={<CustomLineTooltip />} />
                <Legend />
                <Line type="monotone" dataKey="totalUsers" name="Total Users" stroke="#82ca9d" />
                <Line type="monotone" dataKey="dailyJoined" name="Daily Joined" stroke="#ff7300" />
                <Line type="monotone" dataKey="emailVerified" name="Email Verified" stroke="#00c49f" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">User Plans Breakdown</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie data={planData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} label>
                  {planData.map((entry, idx) => (
                    <Cell key={idx} fill={PIE_COLORS[idx % PIE_COLORS.length]} />
                  ))}
                </Pie>
                <RechartsTooltip content={renderCustomPieTooltip} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Move In/Out by Container Location</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={containerLocationChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="locationShortName" />
                <YAxis />
                <RechartsTooltip content={renderContainerTooltip} />
                <Legend />
                <Bar dataKey="moveOut" name="Move Out" fill={BAR_COLORS[1]} />
                <Bar dataKey="moveIn" name="Move In" fill={BAR_COLORS[0]} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Move In Appointments by Container Location</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={moveInChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="containerShortName" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                {moveInKeys.map((key, idx) => (
                  <Bar key={key} dataKey={key} name={formatAppointmentLabel(key)} fill={BAR_COLORS[idx % BAR_COLORS.length]} />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Move Out Appointments by Container Location</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={moveOutChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="containerShortName" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                {moveOutKeys.map((key, idx) => (
                  <Bar key={key} dataKey={key} name={formatAppointmentLabel(key)} fill={BAR_COLORS[idx % BAR_COLORS.length]} />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Special Appointments Overview</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie data={specialAppAggData} dataKey="value" nameKey="group" cx="50%" cy="50%" outerRadius={80} label>
                      {specialAppAggData.map((entry, idx) => (
                        <Cell key={idx} fill={PIE_COLORS[idx % PIE_COLORS.length]} />
                      ))}
                    </Pie>
                    <RechartsTooltip content={renderCustomPieTooltip} />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle1">Revenue Breakdown</Typography>
                  {Object.entries(specialAppRevenueData).map(([group, data]) => (
                    <Box key={group} sx={{ display: 'flex', justifyContent: 'space-between', py: 1, borderBottom: '1px solid #ccc' }}>
                      <Typography>{group}</Typography>
                      <Typography>
                        {data.count} × ${data.revenue.toLocaleString()}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
