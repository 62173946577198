import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    InputAdornment,
    Button,
    Divider,
    Checkbox,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import {
    FilterAltOutlined,
    FilterAlt,
    Search,
    ArrowDropDown,
    ArrowDropUp,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
    collectionGroup,
    getDocs,
    query,
    orderBy,
    deleteDoc,
    doc,
    getDoc,
} from 'firebase/firestore';
import { db } from '../../firebase';

// Helper to format Firestore timestamps
const formatDate = (ts) =>
    ts?.seconds ? new Date(ts.seconds * 1000).toLocaleString() : 'N/A';

const InvoiceHistory = () => {
    const theme = useTheme();

    const [invoiceData, setInvoiceData] = useState([]);
    const [globalSearch, setGlobalSearch] = useState('');
    const [filters, setFilters] = useState({});
    const [filterAnchors, setFilterAnchors] = useState({});
    const [filterSearch, setFilterSearch] = useState('');
    const [sortOrder, setSortOrder] = useState({});
    const [deleteTarget, setDeleteTarget] = useState(null);

    // --- Columns definition ---
    const columns = [
        { key: 'fullName', label: 'User Name' },
        { key: 'email', label: 'Email' },
        { key: 'id', label: 'Invoice ID' },
        { key: 'createdAt', label: 'Created At' },
        { key: 'dueDate', label: 'Due Date' },
        { key: 'status', label: 'Status' },
        { key: 'amount', label: 'Amount' },
        { key: 'paidDate', label: 'Paid Date' },
        { key: 'actions', label: 'Actions' },
    ];

    // --- Load invoices and user info ---
    useEffect(() => {
        const loadInvoices = async () => {
            try {
                const q = query(
                    collectionGroup(db, 'invoices'),
                    orderBy('createdAt', 'desc')
                );
                const snap = await getDocs(q);

                const data = await Promise.all(
                    snap.docs.map(async (invoiceSnap) => {
                        const inv = invoiceSnap.data();
                        const [, userID] = invoiceSnap.ref.path.split('/');

                        let firstName = '', lastName = '', email = 'N/A';
                        try {
                            const userSnap = await getDoc(doc(db, 'users', userID));
                            if (userSnap.exists()) {
                                const u = userSnap.data();
                                firstName = u.firstName || '';
                                lastName = u.lastName || '';
                                email = u.email || 'N/A';
                            }
                        } catch (userErr) {
                            console.error(`Error fetching user ${userID}:`, userErr);
                        }

                        const fullName = `${firstName} ${lastName}`.trim() || 'N/A';

                        let status = 'Pending';
                        if (inv.isCancelled) status = 'Cancelled';
                        if (inv.isPaid) status = 'Paid';
                        let amount = 'Unpaid';
                        const paymentDetails = inv.paymentDetails;
                        if (paymentDetails) {
                            const totals = paymentDetails.invoiceTotals;
                            if (totals) {
                                amount = totals.total;
                            }
                        }

                        return {
                            docRef: invoiceSnap.ref,
                            fullName,
                            email,
                            id: invoiceSnap.id,
                            createdAt: formatDate(inv.createdAt),
                            dueDate: formatDate(inv.dueDate),
                            status,
                            amount,
                            paidDate: formatDate(inv.paidDate),
                        };
                    })
                );

                setInvoiceData(data);
            } catch (err) {
                console.error('Error fetching invoice documents:', err);
            }
        };

        loadInvoices();
    }, []);

    // --- Delete a single invoice ---
    const handleConfirmDelete = async () => {
        if (!deleteTarget) return;
        try {
            await deleteDoc(deleteTarget.docRef);
            setInvoiceData((prev) =>
                prev.filter((row) => row.docRef !== deleteTarget.docRef)
            );
        } catch (err) {
            console.error('Error deleting invoice:', err);
        } finally {
            setDeleteTarget(null);
        }
    };

    // --- Filtering, searching, sorting ---
    const rows = invoiceData;
    const searchedRows = useMemo(() => {
        if (!globalSearch) return rows;
        return rows.filter((row) =>
            Object.values(row)
                .filter((v) => v != null)
                .some((v) =>
                    v.toString().toLowerCase().includes(globalSearch.toLowerCase())
                )
        );
    }, [globalSearch, rows]);

    const filteredRows = searchedRows.filter((row) =>
        Object.keys(filters).every((col) => {
            if (filters[col]?.length) return filters[col].includes(row[col]);
            return true;
        })
    );

    const sortedRows = useMemo(() => {
        const sortCol = Object.keys(sortOrder).find((c) => sortOrder[c]);
        if (!sortCol) return filteredRows;
        const order = sortOrder[sortCol];
        return [...filteredRows].sort((a, b) => {
            if (a[sortCol] < b[sortCol]) return order === 'asc' ? -1 : 1;
            if (a[sortCol] > b[sortCol]) return order === 'asc' ? 1 : -1;
            return 0;
        });
    }, [filteredRows, sortOrder]);

    // --- Filter helpers ---
    const getUniqueColumnValues = (key) =>
        Array.from(new Set(rows.map((r) => r[key] || 'N/A')));

    const handleFilterClick = (e, key) =>
        setFilterAnchors((f) => ({ ...f, [key]: e.currentTarget }));
    const handleFilterClose = (key) =>
        setFilterAnchors((f) => ({ ...f, [key]: null }));
    const handleFilterSearchChange = (e) => setFilterSearch(e.target.value);
    const handleFilterChange = (key, val) =>
        setFilters((f) => {
            const cur = f[key] || [];
            return {
                ...f,
                [key]: cur.includes(val)
                    ? cur.filter((x) => x !== val)
                    : [...cur, val],
            };
        });
    const handleSelectAll = (key) =>
        setFilters((f) => ({ ...f, [key]: getUniqueColumnValues(key) }));
    const handleDeselectAll = (key) =>
        setFilters((f) => ({ ...f, [key]: [] }));
    const handleSort = (key) =>
        setSortOrder(() => {
            const cur = sortOrder[key];
            return { [key]: cur === 'asc' ? 'desc' : 'asc' };
        });

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>
                Invoice History
            </Typography>

            {/* Global Search */}
            <Box sx={{ mb: 2 }}>
                <TextField
                    placeholder="Search Invoices..."
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={globalSearch}
                    onChange={(e) => setGlobalSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search fontSize="small" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <TableContainer component={Box} sx={{ overflowX: 'auto' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((col) => (
                                <TableCell key={col.key} sx={{ fontWeight: 'bold' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {col.label}
                                        {col.key !== 'actions' && (
                                            <>
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => handleFilterClick(e, col.key)}
                                                    sx={{
                                                        fontWeight: filters[col.key]?.length
                                                            ? 'bold'
                                                            : 'normal',
                                                    }}
                                                >
                                                    {filters[col.key]?.length ? (
                                                        <FilterAlt fontSize="small" />
                                                    ) : (
                                                        <FilterAltOutlined fontSize="small" />
                                                    )}
                                                </IconButton>
                                                <Menu
                                                    anchorEl={filterAnchors[col.key]}
                                                    open={Boolean(filterAnchors[col.key])}
                                                    onClose={() => handleFilterClose(col.key)}
                                                >
                                                    <Box sx={{ p: 1 }}>
                                                        <TextField
                                                            size="small"
                                                            fullWidth
                                                            placeholder="Search..."
                                                            value={filterSearch}
                                                            onChange={handleFilterSearchChange}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <Search fontSize="small" />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                mt: 1,
                                                            }}
                                                        >
                                                            <Button
                                                                size="small"
                                                                onClick={() => handleSelectAll(col.key)}
                                                            >
                                                                Select All
                                                            </Button>
                                                            <Button
                                                                size="small"
                                                                onClick={() => handleDeselectAll(col.key)}
                                                            >
                                                                Deselect All
                                                            </Button>
                                                        </Box>
                                                        <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                                            {getUniqueColumnValues(col.key)
                                                                .filter((v) =>
                                                                    String(v)
                                                                        .toLowerCase()
                                                                        .includes(filterSearch.toLowerCase())
                                                                )
                                                                .map((val) => (
                                                                    <MenuItem
                                                                        key={val}
                                                                        onClick={() =>
                                                                            handleFilterChange(col.key, val)
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={
                                                                                filters[col.key]?.includes(val) ||
                                                                                false
                                                                            }
                                                                            size="small"
                                                                        />
                                                                        <ListItemText primary={val} />
                                                                    </MenuItem>
                                                                ))}
                                                        </Box>
                                                        <Divider sx={{ my: 1 }} />
                                                        <Button
                                                            size="small"
                                                            onClick={() => handleSort(col.key)}
                                                        >
                                                            {sortOrder[col.key] === 'asc' ? (
                                                                <ArrowDropUp fontSize="small" />
                                                            ) : (
                                                                <ArrowDropDown fontSize="small" />
                                                            )}
                                                            Sort
                                                        </Button>
                                                    </Box>
                                                </Menu>
                                            </>
                                        )}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedRows.map((row, idx) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    backgroundColor:
                                        idx % 2 === 0 ? theme.palette.action.hover : 'inherit',
                                }}
                            >
                                {columns.map((col) => (
                                    <TableCell key={col.key} sx={{ py: 0.5 }}>
                                        {col.key === 'actions' ? (
                                            <IconButton
                                                size="small"
                                                onClick={() => setDeleteTarget(row)}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        ) : (
                                            row[col.key]
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={Boolean(deleteTarget)}
                onClose={() => setDeleteTarget(null)}
            >
                <DialogTitle>Delete Invoice?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete invoice “{deleteTarget?.id}” for{' '}
                        {deleteTarget?.fullName}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteTarget(null)}>Cancel</Button>
                    <Button color="error" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default InvoiceHistory;
